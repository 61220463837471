.wrapper {
  padding: 20px;
  width: 100%;

  .title {
    font-size: 20px;
    margin-bottom: 20px;
  }

  .main-col {
    max-width: 300px;
  }

  :global {
    .ant-form {
      label {
        font-weight: bold;

        &:global(.ant-form-item-required) {
          position: relative;

          &::after {
            display: block !important;
            visibility: visible !important;
            content: "*";
            position: absolute;
            right: -8px;
            top: 0;
            font-size: 10px;
            margin-inline: 0;
            padding: 0;
            color: red;
          }
        }
      }
    }
  }
}

.wrapper-landing {
  @media screen and (max-width: 640px) {
    display: block;
    width: auto;
  }

  .title {
    font-size: 20px;
    color: #ffffff;
    margin-bottom: 35px;

    @media screen and (max-width: 640px) {
      text-align: center;
    }
  }

  .main-col {
    @media screen and (max-width: 640px) {
      margin: auto;
    }
  }

  :global {
    .ant-form {
      label {
        color: rgba(255, 255, 255, 0.56);
      }

      .ant-input {
        background-color: rgba(255, 255, 255, 0.025);
        border: 1px solid rgba(255, 255, 255, 0.05);
        border-radius: 8px;
        color: white;

        &:focus {
          box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
        }
      }

      .ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
        background-color: #7a4ffb;
        background-image: linear-gradient(to right, #f4aa41, #e84577, #7a4ffb);
        background-position: bottom;
        background-size: 100% 3px;
        background-repeat: no-repeat;
      }
      .ant-input-outlined.ant-input-status-error:not(
          .ant-input-disabled
        ):hover {
        background-color: unset !important;
      }

      .ant-btn {
        background-color: #7a4ffb;
        background-image: linear-gradient(to right, #f4aa41, #e84577, #7a4ffb);
        background-position: bottom;
        background-size: 100% 3px;
        background-repeat: no-repeat;
        height: 35px;
      }
    }
  }

  .schedule-btn-wrapper {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  .selected-date {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.56);
    font-weight: 500;
  }
}

.wrapper-profile {
  border: 0.5px solid rgba(11, 11, 11, 0.1);
  border-radius: 6px;
  width: unset;
  flex: 1;
  background-color: white;
  padding: 26px;

  @media screen and (max-width: 640px) {
    display: block;
    width: auto;
  }

  .text-are-profile {
    width: 100%;
  }

  .title {
    font-size: 16px;
    color: #262626;
    margin: 0px 0px 22px 0px;

    @media screen and (max-width: 640px) {
      text-align: center;
    }
  }

  .main-col {
    max-width: none;

    @media screen and (max-width: 640px) {
      margin: auto;
    }
  }

  :global {
    .ant-form {
      label {
        color: #595959;
        font-weight: 500;
      }

      .ant-input {
        background-color: rgba(255, 255, 255, 0.025);
        border: 1px solid #cecece;
        border-radius: 4px;
        color: #595959;
        width: 100%;

        &:placeholder-shown {
          color: #595959;
        }

        &:focus {
          box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
        }
      }

      .ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
        background-color: #f9c931;
        color: #262626;
        height: 40px;
        width: auto;
        font-weight: 500;
        border-radius: 8px;
        padding: 8px 10px !important;
      }

      .ant-btn {
        background-color: #f7cf51;
        height: 40px;
        width: auto;
        color: #262626;
        font-size: 14px;
        font-weight: 500;
        border-radius: 8px;
        padding: 8px 10px !important;
      }
    }
  }

  .schedule-btn-wrapper {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  .selected-date {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.56);
    font-weight: 500;
  }
}
