@import "../../../styles/mixins";

.event-type-details {
  text-align: center;
  margin: 30px 0px;
}
.back {
  float: left;
  @extend .back-button;
}

.color-options {
  :global {
    .ant-radio {
      display: none;
    }
  }

  label {
    height: 35px;
    width: 35px;
    border-radius: 50%;
    margin-bottom: 40px;

    &:global(.ant-radio-wrapper-checked) {
      position: relative;

      &:before {
        content: "";
        position: absolute;
        top: 46%;
        left: 52%;
        width: 15px;
        height: 9px;
        border-top: 4px solid #fff;
        border-right: 4px solid #fff;
        transform: translate(-50%, -50%) rotate(135deg);
      }
    }
  }
}

.submit-btn-cont {
  display: flex;
  justify-content: flex-end;
  margin-left: -30px;
  width: calc(100% + 60px);
  border-top: 1px solid #cccccc;
  padding: 20px;
  gap: 30px;

  &.step-two {
    margin-left: 0;
    width: 100%;
  }
}

.wrapper {
  :global {
    .ant-collapse-header[aria-expanded="true"] {
      border-bottom: 0.5px solid #cccccc;
    }

    .ant-collapse-item {
      border: 1px solid #666a73;
      margin-bottom: 20px;
    }

    .ant-row + .ant-collapse {
      background: none;
      border: none;
    }
  }

  .step-one {
    label {
      font-size: 14px;
      font-weight: bold;
      color: #1a1a1a;
    }

    .link-label {
      :global {
        .ant-form-item-label {
          padding: 0px;
        }
      }

      label {
        &:before {
          margin-top: -22px !important;
        }

        div {
          p {
            &:first-of-type {
              font-weight: bold;
            }

            &:last-of-type {
              font-weight: normal;
              font-size: 16px;
              margin-top: 5px;
            }
          }
        }
      }
    }
  }

  .step-two {
    .range {
      :global {
        .ant-picker-range {
          border-radius: 10px;

          .ant-picker-input {
            input {
              padding: 5px;
            }
          }
        }
      }
    }
  }

  .step-one,
  .invitee-questions,
  .payment-details {
    :global {
      .ant-collapse-content {
        .ant-collapse-content-box {
          padding: 30px 30px 0px 30px !important;
        }
      }
    }
  }

  &:global(
      .ant-collapse-ghost .ant-collapse-content .ant-collapse-content-box
    ) {
    padding: 0px;
  }

  &:global(.ant-collapse-borderless .ant-collapse-item:last-child) {
    border-bottom: 1px solid #666a73;
    // border-radius: 15px;
  }

  .section {
    padding: 30px;
    border-bottom: 0.5px solid #cccccc;
    input {
      padding: 20px;
      border-radius: 10px;
    }
    :local .custom-schedule-wrapper {
      input {
        padding: 0px;
      }
    }

    :global {
      .ant-select-selector {
        height: auto;
        padding: 5px 15px;
        border-radius: 10px;
      }
    }

    :global {
      .m-icons {
        height: 100%;
        vertical-align: middle;
      }
    }

    .schedule-cont {
      border: 1px solid #cccccc;
      border-radius: 10px;

      h3 {
        margin-bottom: 15px;
      }

      p {
        font-size: 16px;

        &.time {
          margin-bottom: 5px;
        }

        &:not(.time) {
          color: rgba(26, 26, 26, 0.612);
        }
      }

      .bold {
        font-weight: bold;
      }

      .day,
      .date {
        padding-top: 2px;
      }

      .weekday-list {
        padding: 15px;
        border-right: 1px solid #cccccc;

        :global {
          .ant-row {
            margin-bottom: 20px;
          }
        }
      }

      .date-override-list {
        padding: 15px;

        :global {
          .ant-row {
            margin-bottom: 20px;
          }
        }
      }
    }
  }

  .additional-rules {
    border-radius: 0px !important;
    border: none !important;
    margin-bottom: 0px !important;

    :global {
      .ant-collapse-header {
        padding: 30px;
        background: none !important;
      }
    }
  }

  .invitee-questions {
    margin-top: 70px;
    position: relative;

    &:before {
      content: "Additional Questions";
      position: absolute;
      top: -50px;
      font-weight: bold;
    }

    button {
      height: auto;
      padding: 0px;
      display: flex;
      align-items: center;
      margin-bottom: 15px;
    }

    .submit-btn-cont {
      button {
        padding: 5px 15px;
      }
    }

    .question-cont {
      padding: 10px;
      width: 40%;
      border-radius: 5px;
      cursor: pointer;
      position: relative;

      & > span {
        position: absolute;
        left: -16px;
        top: 11px;
      }

      .question {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        position: relative;
        .settings-icon {
          visibility: hidden;
          margin-top: 2px;
        }
        .fixed-index {
          position: absolute;
          left: -26px;
          top: 1px;
        }
        .default-switch {
          margin-top: 4px;
          margin-left: 8px;
          padding: 1px 4px;
          border: 1px solid #666;
          border-radius: 2px;
          background-color: #666;
          color: var(--text-color-secondary-4, rgba(255, 255, 255, 1));
          font-size: 12px;
          line-height: 1em;
          text-transform: uppercase;
        }
      }

      &:hover {
        background: #f2f2f2;

        .settings-icon {
          visibility: visible;
        }
      }

      .OneLine {
        height: 40px;
        border: 1px solid #d0d0d0;
        border-radius: inherit;
      }

      .MultipleLines {
        height: 120px;
        border: 1px solid #d0d0d0;
        border-radius: inherit;
      }
      .Dropdown {
        height: 40px;
        border: 1px solid #d0d0d0;
        border-radius: inherit;
        display: flex;
        align-items: center;
        padding-left: 20px;

        p {
          color: #d0d0d0;
        }
      }

      .option {
        .box {
          padding: 5px;
          display: inline-block;
          height: 13px;
          width: 13px;
          border-radius: 50%;
          margin-right: 10px;
          border: 1px solid #ccc;
        }
      }

      .Checkboxes {
        .box {
          border-radius: 2px;
        }
      }
    }
  }
}

.question-modal {
  button {
    &:global(.ant-btn-link) {
      height: auto;
      padding: 0px;
      display: flex;
      align-items: center;

      :local &.delete-question {
        color: #c84545 !important;
        margin: 30px 0px;
        margin-left: -4px;

        span {
          &:global(:not(.ant-btn-icon)) {
            &.hover {
              text-decoration: underline;
            }
          }
        }
      }

      :local &.add-option {
        margin-bottom: 20px;
      }
    }
  }
}
