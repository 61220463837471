.scheduled-events-wrapper {
  :global {
    .ant-tabs-nav {
      padding-left: 20px;
      margin-bottom: 0px;
    }
  }
 
 
  .date-range {
    display: flex;
    align-items: center;
    :global {
      .m-icons {
        vertical-align: middle;
      }
    }
  }
  .day-range{
    padding: 16px 24px;
    font-size: 20px;

  }
  .day-header {
    padding: 16px 24px;
    border-top: 1px solid var(--text-color-level3, rgba(26, 26, 26, 0.1));
    border-bottom: 1px solid var(--text-color-level3, rgba(26, 26, 26, 0.1));
    background-color: var(--color-bg-grey-2, #fafafa);
    font-size: 14px;
  }
  .wrapper {
    // padding: 20px 18px;
    border-radius: 0;
    :global {
      .ant-collapse {
        & > .ant-collapse-item {
          &:last-child {
            & > .ant-collapse-header {
              background-color: green;
            }
          }
        }
      }
      .ant-collapse-expand-icon{
        padding-left: 0 !important;
      }
      .ant-collapse-header[aria-expanded="true"] {
        border-bottom: 0.5px solid #cccccc;
        border-radius: 0;
      }
      .ant-collapse-header {
        border-bottom: 0.5px solid #cccccc;
        padding: 20px 24px;
        // border-radius: 0 !important;
        &:last-of-type {
          border-radius: 0px !important;
        }
      }
      .ant-collapse-header:hover {
        background-color: var(--color-grey-4, #f2f2f2);
      }
      .ant-collapse-content-box {
        border-bottom: 0.5px solid #cccccc;
        padding: 20px 24px;
        border-radius: 0;
      }
    }
  }
  .collapse-header {
    display: flex;
    justify-content: space-between;
    .profile-container {
      display: flex;
    }

    .profile-color {
      flex: 0 0 auto;
      width: 30px;
      height: 30px;
      margin-right: 10px;
      border: solid 2px #fff;
      border-radius: 50%;
      background-clip: padding-box;
    }
    .collapse-header-time {
      font-size: 14px;
      margin-top: 5px;
    }
    .collapse-header-name {
      font-size: 14px;
      font-weight: bold;
    }
    .collapse-event-type {
      font-size: 14px;
      .collapse-event-name {
        font-size: 14px;
        font-weight: bold;
        margin-left: 5px;
      }
    }

    .collapse-details {
      color: var(--text-color-level2, rgba(26, 26, 26, 0.61));
    }
  }

  .collapse-panel-content {
    display: flex;
    margin-left: -10px;

    .content-section {
      .email-container {
        margin-bottom: 16px;
      }
      .location-container {
        margin-bottom: 16px;
      }
      .questions-container {
        margin-bottom: 16px;
        .panel-questions {
          margin-bottom: 20px;
        }
        .interview-questions {
          color: var(--text-color-level2, rgba(26, 26, 26, 0.61));
        }
      }
      .section-email {
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 8px;
      }
      .customer-email {
        font-size: 14px;
      }
    }
  }
  .events-wrapper {
    padding: 15px;
  }

  .popover-content {
    .range-picker {
      position: static;
    }
    :global {
      .ant-picker-range {
        //display: none;
       // z-index: 10000000;
      }
      .ant-picker-dropdown {
        .ant-picker-range-arrow {
          display: none;
        }
      }
    }
  }
}

.cancel-button {
  margin-right: 10px;
}

.popover-footer{
  .footer-buttons{
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  label{
    margin-right: 10px;
    border-radius: 5px;
  }
}


