.heading {
  margin-top: 15px;
}

.description {
  margin-bottom: 25px;
}

.schedules {
  margin-bottom: 20px;
  display: flex;

  div:first-of-type {
    display: flex;
    gap: 20px;
  }

  button {
    display: flex;

    &.add-schedule-button {
      border-radius: 20px;
      margin-left: auto;
    }

    &.selected {
      box-shadow: 0 2px 12px 0 rgb(0 0 0/15%);
      font-weight: 600;
    }
  }
}

.schedule-details {
  padding: 20px;
  border-bottom: 1px solid #cccccc;

  .default-cont {
    display: flex;

    p {
      font-size: 14px;
      color: #1a1a1a9c;
      margin-left: 5px;
    }

    :global {
      .m-icons {
        font-size: 20px;
        color: #f2ce0e;
        margin-left: 0px;
      }
    }
  }

  :global {
    .ant-segmented {
      padding: 5px;
      border-radius: 12px;

      .ant-segmented-item-label {
        display: flex;

        :global {
          .m-icons {
            vertical-align: middle;
            margin-bottom: 2px;
          }
        }
      }
    }
  }
}

.settings-dropdown {
  :global {
    .ant-dropdown-menu-title-content {
      display: flex;

      .m-icons {
        font-size: 20px;
        margin-right: 5px;
      }
    }
  }
}

.delete-confirmation-modal {
  :global {
    .ant-modal-title {
      font-size: 24px;
    }

    .ant-modal-body {
      color: #1a1a1a;
      padding: 10px 0px;
      font-size: 16px;
      line-height: 22px;
    }

    .ant-modal-footer {
      button {
        font-size: 16px;
        height: auto;
      }
    }
  }
}
