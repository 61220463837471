.outer-wrapper {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f5f5f5;
  .auth-layout-wrapper {
    // width: 60%;
    width: max-content;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    margin: 50px 0px;
    width: 400px;
    .left-content {
      border-radius: 12px 0px 0px 12px;
      background: url("../../../assets/images/logo-bg.jpg");
      background-repeat: no-repeat;
      background-position: top center;
      background-size: cover;
    }

    .right-content {
      border-radius: 12px;
      padding: 30px;
      background: white;
      text-align: center;
      h1{
        font-size: 30px;
        line-height: 28px;
      }
    }
  }
}
