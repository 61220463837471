.calendar-wrapper {
  .has-slots {
    background-color: rgba(247, 207, 81, 0.15) !important;
    border-radius: 50%;
    cursor: pointer;
  }

  .custom-selected {
    background-color: #f7cf51 !important;
  }

  .title {
    font-weight: 500;
    font-size: 16px;
    padding: 11px 0 11px 22px;
  }

  .month-changer {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin: 15px 0;
    // padding-left: 22px;

    .month {
      padding-left: 20px;
      padding-bottom: 10px;
      display: flex;
      gap: 10px;
    }

    .icons {
      display: flex;
      gap: 16px;

      .icon-btn {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background: none;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          background-color: #f5f5f5;
        }
      }

      .change-icons {
        width: 10px;
        height: 10px;
      }
    }
  }

  :global {
    .ant-picker-cell-disabled::before {
      background-color: unset;
    }

    .ant-picker-calendar-header {
      display: flex;
      justify-content: flex-start;
      padding: 12px 0;
    }

    .ant-picker-cell {
      padding: 2px 0;
      cursor: default;

      .ant-picker-cell-inner {
        height: 32px;
        width: 32px;
        line-height: 32px;
        border-radius: 50%;
        color: black !important;
      }

      &:hover {
        .ant-picker-cell-inner:not(:local .has-slots) {
          background-color: unset !important;
        }

        :local .has-slots:not(.custom-selected) {
          background-color: #f7cf51 !important;
        }
      }

      &.ant-picker-cell-today .ant-picker-cell-inner::before {
        border: none;
      }

      &.ant-picker-cell-selected .ant-picker-cell-inner:local:not(.has-slots) {
        background-color: unset !important;
      }

      &:not(.ant-picker-cell-in-view) .ant-picker-cell-inner {
        color: rgba(0, 0, 0, 0.25) !important;
      }
    }
  }
}

.calendar-wrapper-landing {
  width: 100%;

  .has-slots {
    background-color: rgba(247, 207, 81, 0.15) !important;
    border-radius: 50%;
    cursor: pointer;
  }

  .no-slots {
    opacity: 0.5;
  }

  .custom-selected {
    background-color: #6637ef !important;
  }

  .title {
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
  }

  .month-changer {
    .month {
      padding-left: 20px;
      padding-bottom: 10px;
      display: flex;
      gap: 10px;
      color: #ffffff;
      font-size: 18px;
      font-weight: 600;
    }

    .date {
      display: flex;
      gap: 8px;
    }

    .icons {
      .icon-btn {
        &:hover {
          background-color: #6637ef;
        }
      }
    }
  }

  :global {
    .ant-picker-calendar {
      background: inherit;
    }

    .ant-picker-panel {
      background: inherit;
    }

    .ant-picker-content th {
      font-size: 12px;
      font-weight: 700;
      color: rgba(255, 255, 255, 0.5);
    }

    .ant-picker-calendar-date-value {
      color: #d0d0d0 !important;
    }

    .ant-picker-cell {
      // padding: 2px 0;

      &:hover {
        .ant-picker-cell-inner:not(:local .has-slots) {
          background-color: unset !important;
        }

        :local .has-slots:not(.custom-selected) {
          background-color: #6637ef !important;
        }
      }

      &:not(.ant-picker-cell-in-view) .ant-picker-cell-inner {
        color: white !important;
      }
    }
  }
}

.calendar-wrapper-profile {
  :global {
    .ant-picker-panel {
      border-top: none;
      width: 497px;
      border: 1px solid #f0f0f0;
      border-radius: 14px;

      .ant-picker-body {
        padding: 0;
        overflow: hidden;

        .ant-picker-content {
          height: 97px;
          // border: 1px solid #f0f0f0;
          border-collapse: unset;
          border-spacing: 0;
          overflow: hidden;
          border-radius: 14px;
          // padding-top: 15px;
          // width: 497px !important;

          thead {
            background-color: #fafafa;

            th {
              padding: 8px;
            }

            td {
              padding: 0px;
            }
          }

          .ant-picker-cell {
            padding: 0;
            border-left: 1px solid white;

            :local .has-slots:not(.custom-selected) {
              background-color: unset !important;
            }

            .ant-picker-cell-inner {
              height: 100%;
              border-radius: 0px;
              width: 100%;
              font-size: 16px;
              color: #262626 !important;
              font-weight: 500;
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              line-height: 22px;

              .ant-picker-calendar-date-value {
                display: flex;
                flex-direction: column;
              }
            }

            &:hover {
              :local(.has-slots) {
                background-color: #f7cf51 !important;
              }
            }

            &.ant-picker-cell-disabled {
              opacity: 0.4;
            }
          }
        }
      }
    }
  }

  .title {
    font-weight: 700;
    margin: 0px;
    padding: 0px;
  }

  .with-btn {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;

    .icon-btn {
      border: 1px solid #f0f0f0;
      border-radius: 50% !important;
      width: 34px !important;
      height: 34px !important;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .month-label {
      margin: 0px;
      font-size: 12px;
      font-weight: 600;
      color: #595959;
    }
  }
}
