.wrapper {
  padding: 20px;

  .add-button {
    width: 100%;
    margin-top: 10px;
  }
}

.date-override-list {
  border-top: 1px solid #cccccc;

  :global {
    .ant-row {
      padding: 20px;
      border-bottom: 1px solid #cccccc;

      &:hover {
        background: rgba(0, 105, 255, 0.065);
        cursor: pointer;
      }
    }
  }
}
