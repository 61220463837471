.amount{
    :global{
        .ant-input-number{
            padding-top: 2px;
        }
    }
}
.confirmation-modal{
    :global{
        .ant-modal-content{
            .ant-modal-title{
                font-size: 18px;
            }
            .ant-modal-body{
                p{
                    font-size: 16px;
                    &:last-of-type{
                        margin: 20px 0px;
                        font-weight: bold;
                    }
                }
            }
        }
    }
}