.title {
  text-align: center;
  font-size: 26px;
}
.wrapper {
  width: 55%;
  margin: 40px auto;
  :global {
    .ant-row {
      :local {
        .desc {
          margin: 10px 0px;
        }
        .icon {
          height: 100%;
          display: flex;
          align-items: center;
        }
      }
      padding: 20px;
      border: 1px solid #cccccc;
      position: relative;
      &:first-of-type {
        border-radius: 15px 15px 0px 0px;
      }
      &:last-of-type {
        border-radius: 0px 0px 15px 15px;
      }
      &:not(:last-of-type) {
        border-bottom: none;
        &:after {
          position: absolute;
          content: "";
          border: 0.5px solid #cccccc;
          bottom: -1px;
          width: calc(100% + 2px);
          left: -1px;
          height: 0px;
          // z-index: 99;
        }
      }
      &:hover {
        border-color: blue;
        cursor: pointer;
        &:not(:last-of-type) {
          &:after {
            border-color: blue;
            z-index: 99;
          }
        }
        :local {
          .icon {
            color: blue;
          }
        }
      }
    }
  }
}
