@import "../../../styles/variables";

.calendar-page-container {
  display: flex;
}

.calendar-left-content {
  border-right: 1px solid #e5e5e5;
  padding: 20px 30px;
  width: 40%;
  min-width: 40%;

  .calender {
    width: 18px;
    height: 20px;
    margin-bottom: 25px;
  }

  .session-details {
    color: $color-text-lighter;
    font-weight: 500;
    font-size: 14px;
    padding-bottom: 25px;
    padding-top: 30px;
  }

  .stage-details {
    font-weight: 600;
    font-size: 15px;
    padding-bottom: 25px;
  }

  .time-detail {
    display: flex;
    gap: 10px;
    color: $color-text-lighter;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    padding-bottom: 31px;
  }

  .interview-time {
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    padding-left: 10px;
  }
}

.calendar-right-content {
  padding: 20px;
  width: 100%;

  .right-scroll {
    display: flex;
    gap: 10px 10px;
    flex-wrap: wrap;
    margin-top: 12px;
    margin-bottom: 12px;
    padding-right: 20px;

    .time-slot-btn {
      padding: 2px 12px;
      border-radius: 4px;
      background-color: inherit;
      border: 1px solid $color-link-primary;
      background-color: rgba(255, 255, 255, 0.04);
      transition: width 0.3s ease-in-out;

      &::after {
        box-shadow: none !important;
      }

      &[disabled] {
        cursor: default;
        background-color: #e5e5e5;
        border: none;
      }
    }

    .selected-time-slot-btn-wrapper.time-slot-btn {
      cursor: default;
      background-color: $color-link-primary;
      border-color: $color-link-primary;

      &:hover {
        color: black;
      }
    }
  }
}

.calendar-page-container-landing {
  height: auto;
  max-height: 100%;
  overflow: auto;

  @media screen and (max-width: 640px) {
    display: block;
    width: auto;
  }

  .calendar-wrapper {
    padding: 35px 0px;
  }

  .calendar-right-content {
    @media screen and (max-width: 640px) {
      padding: 0px;
      width: auto;
    }
  }

  .date-selected {
    color: rgba(255, 255, 255, 0.56);
    font-size: 15px;
    font-weight: 600;
  }

  .time-slot-btn {
    border: 1px solid rgba(255, 255, 255, 0.05) !important;
    color: rgba(255, 255, 255, 0.56);
  }

  .selected-time-slot-btn-wrapper.time-slot-btn {
    cursor: default;
    color: white !important;
    background-color: #6637ef !important;
    border: none;
  }
}

.calendar-page-container-profile {
  height: auto;
  max-height: 100%;
  overflow: auto;
  gap: 20px;

  @media screen and (max-width: 640px) {
    display: block;
    width: auto;
  }

  .calendar-left-content {
    border: 0.5px solid rgba(11, 11, 11, 0.1) !important;
  }

  .calendar-wrapper {
    padding: 35px 0px;
  }

  .calendar-right-content {
    border: 0.5px solid rgba(11, 11, 11, 0.1) !important;
    width: 100%;
    border-radius: 6px;
    flex: 1;
    background-color: white;
    padding: 26px;

    @media screen and (max-width: 640px) {
      padding: 0px;
      width: auto;
    }
  }

  .date-selected {
    color: #595959;
    font-size: 14px;
    font-weight: 500;
  }

  .time-slot-btn {
    border: 1px solid #f0f0f0 !important;
    color: #262626;
    height: 40px;
    padding: 3px 30px !important;
    border-radius: 14px !important;
    width: 100%;
    max-width: 80px;
  }

  .date-selected-label {
    font-size: 16px;
    font-weight: 700;
  }

  .selected-time-slot-btn-wrapper.time-slot-btn {
    cursor: default;
    color: #262626 !important;
    background-color: #f7cf51 !important;
    border: none;
    font-weight: 600;
    border-radius: 14px;
    padding: 3px 30px;
    height: 40px;
  }
}