@import "../../styles/mixins";

.login-form {
  button {
    &:global(.ant-btn) {
      width: 100%;
      height: auto;
      display: flex;
      justify-content: center;
      border-radius: 5px;
    }

    &.login-button {
      margin-top: 10px;
      padding: 7px 0px;
    }

    &.google-button {
      padding: 5px;
      margin-bottom: 20px;
    }
  }

  .sign-up-link {
    @extend .back-button;
    color: #1a1a1a9c;
  }
}
.google-button {
  padding: 10px;
  margin: 0 auto;
  height: auto;
  display: flex;
  justify-content: center;
  border-radius: 5px;
  font-size: 16px;
  img {
    margin-right: 10px;
    height: 24px;
    width: 24px;
  }
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}
