.back-button {
    display: flex;
    gap: 8px;
    width: fit-content;
    margin: 30px auto 0 !important;
    text-decoration: none;
    font-size: 16px;

    :global {
        .m-icons {
            font-size: 18px;
        }
    }
}