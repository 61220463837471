@import "../../../styles/mixins";

.container {
  display: flex;
  justify-content: center;
  height: 100vh;

  .result-cont {
    width: max-content;
    box-shadow: 0 2px 12px 0 rgb(0 0 0/15%);
    margin-top: 50px;
    min-width: 350px;

    &:global(.ant-result-error) {
      :global {
        .ant-result-title {
          color: #ff4d4f;
        }
      }
    }

    .details {
      list-style-type: none;

      li {
        display: flex;
        justify-content: space-between;
        gap: 30px;
        align-items: center;
        margin-bottom: 10px;

        span {
          &:first-of-type {
            min-width: 150px;
            text-align: left;
          }
        }
      }
    }

    a {
      @extend .back-button;
    }
  }
}

.confirmation-wrapper {
  margin-top: 22px;
  border: 1px solid rgba(255, 255, 255, 0.05);
  padding: 50px 20px 80px 20px;
  border-radius: 8px;
  background: rgb(0, 5, 34);
  text-align: center;
  color: white;

  .confirmation-title {
    font-size: 25px;
    margin-bottom: 24px;
    font-weight: 500;
  }
}
