.card-cont {
  padding: 20px;
  border: 1px solid #f2f2f2;
  height: 100%;
  cursor: pointer;
  border-radius: 5px;

  &.integrated {
    background: #9e9eb026;

    &:hover {
      cursor: not-allowed;
    }
  }

  .icons {
    display: flex;
    justify-content: space-between;
  }

  &:hover {
    border: 1px solid #dadada;
    box-shadow: 0 2px 15px 0 #0000001a;
    cursor: pointer;
  }

  .title {
    margin: 10px 0px;
    font-weight: bold;
  }

  .desc {
    color: #1a1a1a9c;
  }
}
