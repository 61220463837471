.title {
  text-align: center;
  margin: 40px 0px 35px 0px;
  color: #1a1a1a9c;
  font-weight: bold;
}
.description {
  text-align: center;
  max-width: 280px;
  margin: auto;
  font-size: 14px;
  font-weight: normal;
  color: #1a1a1a9c;
  line-height: 1.5;
  margin-bottom: 25px;
}

.cards-wrapper {
  width: 80%;
  margin: 0px auto;
  .event-card-container {
    flex: 0 calc(50% - 40px);
    min-height: 180px;
    margin: 20px;
    padding: 20px;
    border-top: 1px solid var(--text-color-level3, rgba(26, 26, 26, 0.1));
    &:hover {
      background: var(--text-color-level3, rgba(26, 26, 26, 0.1));
      outline: none;
      cursor: pointer;
    }

    .event-card {
      display: flex;
      align-items: center;
      height: 40px;
      margin-bottom: 20px;
      .profile-color {
        flex: 0 0 auto;
        width: 30px;
        height: 30px;
        margin-right: 10px;
        border: solid 2px #fff;
        border-radius: 50%;
        background-clip: padding-box;
      }
      .meeting-time {
        flex: 1 1 auto;
        font-weight: bold;
      }
    }
    .desc {
      color: #1a1a1a;
    }
  }
}

.event-page-wrapper {
  display: flex;
  justify-content: center;
  padding-right: 5%;
  padding-left: 5%;
  margin-top: 66px;
  margin-bottom: 30px;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
