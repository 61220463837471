.wrapper {
  // display: flex;
  justify-content: center;
  padding-right: 13%;
  padding-left: 13%;
  margin-top: 66px;
  margin-bottom: 30px;
}

.event-container {
  position: relative;
  border: 1px solid var(--text-color-level3, rgba(26, 26, 26, 0.1));
  border-radius: 8px;
  box-shadow: 0 1px 8px 0 rgb(0 0 0/8%);
}

.ribbon {
  position: absolute;
  right: -5px;
  top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 75px;
  height: 75px;
  text-align: right;

  span {
    font-size: 10px;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    line-height: 20px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    width: 100px;
    display: block;
    background: #79a70a;
    background: linear-gradient(#505960 0%, #505960 100%);
    box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
    position: absolute;
    top: 19px;
    right: -21px;

    &:hover {
      animation: zoom-in-zoom-out 1s ease infinite;
    }

    &::before {
      content: "";
      position: absolute;
      left: 0px;
      top: 100%;
      z-index: -1;
      border-left: 3px solid #505960;
      border-right: 3px solid transparent;
      border-bottom: 3px solid transparent;
      border-top: 3px solid #505960;
    }

    &::after {
      content: "";
      position: absolute;
      right: 0px;
      top: 100%;
      z-index: -1;
      border-left: 3px solid transparent;
      border-right: 3px solid #505960;
      border-bottom: 3px solid transparent;
      border-top: 3px solid #505960;
    }
  }
}

.wrapper-landing {
  padding: 0;
  margin: 0;

  .event-container {
    background-color: #000522;
  }
}

.wrapper-profile {
  background-color: transparent;
  padding: 0px;
  margin: 0px;

  .event-container {
    border: none;
    box-shadow: none;
  }
}