.wrapper {
  border-right: 1px solid #cccccc;
  padding: 20px;

  .slot-weekdays {
    padding: 25px 0px;

    &:not(:last-of-type) {
      border-bottom: 0.3px solid #cccccc;
    }

    .slots {
      display: flex;

      &:not(:last-of-type) {
        margin-bottom: 10px;
      }

      :global {
        .ant-form-item {
          margin-bottom: 0px;
          // input{
          //   padding: 5px;
          // }
        }
      }
    }
  }

  :global {
    .m-icons {
      vertical-align: middle;
      margin-bottom: 2px;
    }
  }
}

.slot-popover-days {
  display: flex;
  gap: 95px;
  margin-top: 19px;
  align-items: center;
}

.slot-popover-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 18px;
  padding-bottom: 8px;
}
