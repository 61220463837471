@import "../../../styles/variables";

.wrapper {
  .input {
    border: 1px solid rgba(0, 0, 0, 0.2);
    outline: none;
    height: 38px;
    box-shadow: none;
    border-radius: 4px;
    width: 100%;

    &:focus {
      box-shadow: none;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}
