.list-wrapper {
  padding: 10px;
  border-bottom: 1px solid #cccccc;
  border-top: 1px solid #cccccc;
  margin-left: -23px;
  width: calc(100% + 46px);
  padding: 20px 30px;

  .heading {
    font-weight: bold;
    color: #1a1a1a;
    margin-bottom: 10px;
  }
  .slots {
    display: flex;
    &:not(:last-of-type) {
      margin-bottom: 10px;
    }
    :global {
      .ant-form-item {
        margin-bottom: 0px;
        // input{
        //   padding: 5px;
        // }
      }
    }
  }
}

.calendar-modal {
  :global {
    .ant-modal-title {
      font-size: 24px;
    }
  }
  .calendar {
    .custom-header {
      display: flex;
      justify-content: space-between;
      margin: 20px 0px;
      padding: 0px 14px;
      p {
        font-size: 16px;
      }
    }
    :global {
      .ant-picker-panel {
        border-top: none;
      }
      .ant-picker-cell {
        &.ant-picker-cell-disabled {
          &:before {
            background-color: unset;
          }
          .ant-picker-cell-inner {
            color: #1a1a1a9c !important;
            &:before {
              border: none;
            }
          }
        }
        &.ant-picker-cell-today {
          .ant-picker-cell-inner {
            background: none;
          }
        }
        &:not(.ant-picker-cell-disabled) {
          .ant-picker-cell-inner {
            color: #0060e6;
            font-weight: 700;
            background: rgba(0, 105, 255, 0.065);
            &:hover {
              background: rgba(0, 105, 255, 0.15) !important;
            }
            &:local(.selected-date) {
              background-color: #0069ff;
              color: white;
              &:hover {
                background-color: #0069ff !important;
              }
            }
          }
        }
        .ant-picker-cell-inner {
          height: 40px;
          width: 40px;
          line-height: 40px;
          border-radius: 50%;
          font-size: 16px;
        }
      }
    }
  }
}
