.content-wrapper {
  padding: 20px 40px;
  background-color: #ffffff;
}

.sider {
  &:global(.ant-layout-sider.ant-layout-sider-dark) {
    height: 100vh;
    position: sticky;
    top: 0;
    left: 0;
    overflow-y: scroll;
  }
  .user-details-container {
    display: flex;
    flex-direction: column;
    padding: 40px 0px;
    align-items: center;
    .user-name{
      color: white;
      font-size: 18px;
      margin:10px 0px;
    }
  }
  .menu {
    :global {
      .ant-menu-item {
        .m-icons {
          font-size: 24px;
          padding-top: 22px;
        }
        .ant-menu-title-content {
          margin-left: 15px;
        }
      }
    }
  }
}
