.wrapper {
  .filter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0 40px;

    button {
      display: flex;
      align-items: center;
      border-radius: 20px;

      :global(.m-icons) {
        color: white;
      }
    }

    :global {
      .ant-input-prefix {
        margin-top: 5px;
      }

      .m-icons {
        font-size: 20px;
        color: rgba(26, 26, 26, 0.61);
      }
    }
  }

  .event-card {
    border-radius: 5px;
    border: 1px solid #cccccc;

    .border-cap {
      height: 5px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      margin-left: -1px;
      margin-right: -1px;
      margin-top: -1px;
      border-top: 6px solid;
    }

    .body {
      padding: 0px 15px 20px 15px;
      border-bottom: 1px solid #eee;

      .setting {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 5px;

        & > div {
          padding: 5px;
        }

        .settings-icon {
          display: flex;
          justify-content: flex-end;
          border: none !important;
          box-shadow: none !important;
          color: #1a1a1a;
          padding: 5px;
          border-radius: 5px;

          &:hover {
            background-color: #eee;
            color: #1a1a1a;
          }

          :global {
            .m-icons {
              font-size: 20px;
              transform: rotate(0deg);
            }
          }

          .rotate {
            transform: rotate(180deg);
          }
        }
      }

      .name {
        font-size: 20px;
        color: rgb(26, 26, 26);
        line-height: 28px;
      }

      .duration {
        color: rgba(26, 26, 26, 0.61);
        font-size: 16px;
        margin-bottom: 15px;
      }
    }

    .footer {
      display: flex;
      justify-content: space-between;
      padding: 10px 15px;

      button {
        height: 30px;

        &:nth-of-type(2) {
          border-radius: 15px;
        }
      }

      .copy-link {
        display: flex;
        padding-left: 0px;
      }
    }

    &:hover {
      box-shadow: 0 2px 12px 0 rgb(0 0 0/15%);
      cursor: pointer;
    }

    &.disabled {
      color: #b2b2b2 !important;

      .name {
        color: inherit;
      }

      .duration {
        color: inherit;
      }

      button,
      a {
        color: inherit;
      }
    }
  }
}

.card-settings-popover {
  background: white;
  width: 200px;
  border-radius: 20px;

  .popover-content {
    background-color: white;

    .options {
      padding: 5px 0px;

      .action {
        display: flex;
        align-items: center;
        padding: 10px 15px;

        :global {
          .m-icons {
            font-size: 20px;
            vertical-align: middle;
          }
        }

        p {
          margin-left: 5px;
        }

        &:hover {
          background: #f2f8ff; //becahy blue
          cursor: pointer;
        }
      }
    }

    .toggle-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px;
      border-top: 1px solid #cccccc;
    }
  }

  :global {
    .ant-popover-content {
      border-radius: 5px;
    }

    .ant-popover-inner {
      padding: 0px;
    }
  }
}

.delete-confirmation-modal {
  :global {
    .ant-modal-title {
      font-size: 24px;
    }

    .ant-modal-body {
      color: #1a1a1a;
      padding: 10px 0px;
      font-size: 16px;
      line-height: 22px;
    }

    .ant-modal-footer {
      button {
        font-size: 16px;
        height: auto;

        &.ant-btn-primary {
          background-color: #c84545;

          &:hover {
            background-color: #b43e3e;
          }
        }
      }
    }
  }
}
